import React, { useState } from 'react'
import { motion } from "framer-motion"
import ImageModal from './ImageModal'
import dazdovka from "../Images/dazdovka.svg"
import slnko from "../Images/slnko.svg"

//#region import images
import zavriet from '../Images/zavriet.svg';
import p1 from '../Photos/JollyPhonics/1.jpg';
import p2 from '../Photos/JollyPhonics/2.jpg';
import p3 from '../Photos/JollyPhonics/3.jpg';
import p4 from '../Photos/JollyPhonics/4.jpg';
import p5 from '../Photos/JollyPhonics/5.jpg';
import p6 from '../Photos/JollyPhonics/6.jpg';
import p7 from '../Photos/JollyPhonics/7.jpg';
import p8 from '../Photos/JollyPhonics/8.jpg';
import p8w from '../Photos/JollyPhonics/8w.jpg';
import p9 from '../Photos/JollyPhonics/9.jpg';
import p9w from '../Photos/JollyPhonics/9w.jpg';
import p10 from '../Photos/JollyPhonics/10.jpg';
import p11 from '../Photos/JollyPhonics/11.jpg';
import p11w from '../Photos/JollyPhonics/11w.jpg';
import p12 from '../Photos/JollyPhonics/12.jpg';
import p13 from '../Photos/JollyPhonics/13.jpg';
import p14 from '../Photos/JollyPhonics/14.jpg';
import p15 from '../Photos/JollyPhonics/15.jpg';
import p16 from '../Photos/JollyPhonics/16.jpg';
import p17 from '../Photos/JollyPhonics/17.jpg';
import p18 from '../Photos/JollyPhonics/18.jpg';
import p19 from '../Photos/JollyPhonics/19.jpg';
import p20 from '../Photos/JollyPhonics/20.jpg';
import p21 from '../Photos/JollyPhonics/21.jpg';
import p22 from '../Photos/JollyPhonics/22.jpg';
import p23 from '../Photos/JollyPhonics/23.jpg';
import p24 from '../Photos/JollyPhonics/24.jpg';
import p25 from '../Photos/JollyPhonics/25.jpg';
import p26 from '../Photos/JollyPhonics/26.jpg';
import p27 from '../Photos/JollyPhonics/27.jpg';
import p28 from '../Photos/JollyPhonics/28.jpg';
import p29 from '../Photos/JollyPhonics/29.jpg';
import p30 from '../Photos/JollyPhonics/30.jpg';
import p31 from '../Photos/JollyPhonics/31.jpg';
import p32 from '../Photos/JollyPhonics/32.jpg';

import a1 from '../Photos/Activities/a1.jpg';
import a2 from '../Photos/Activities/a2.jpg';
import a3 from '../Photos/Activities/a3.jpg';
import a4 from '../Photos/Activities/a4.jpg';
import a5 from '../Photos/Activities/a5.jpg';
import a6 from '../Photos/Activities/a6.jpg';
import a7 from '../Photos/Activities/a7.jpg';
import a8 from '../Photos/Activities/a8.jpg';
import a9 from '../Photos/Activities/a9.jpg';
import a10 from '../Photos/Activities/a10.jpg';
import a11 from '../Photos/Activities/a11.jpg';
import a12 from '../Photos/Activities/a12.jpg';
import a13 from '../Photos/Activities/a13.jpg';
import a14 from '../Photos/Activities/a14.jpg';
import a15 from '../Photos/Activities/a15.jpg';
import a16 from '../Photos/Activities/a16.jpg';
import a17 from '../Photos/Activities/a17.jpg';
import a18 from '../Photos/Activities/a18.jpg';
import a19 from '../Photos/Activities/a19.jpg';
import a20 from '../Photos/Activities/a20.jpg';
import a21 from '../Photos/Activities/a21.jpg';
import a22 from '../Photos/Activities/a22.jpg';
import a23 from '../Photos/Activities/a23.jpg';
import a24 from '../Photos/Activities/a24.jpg';
import a25 from '../Photos/Activities/a25.jpg';
import a26 from '../Photos/Activities/a26.jpg';
import a27 from '../Photos/Activities/a27.jpg';
import a28 from '../Photos/Activities/a28.jpg';
import a29 from '../Photos/Activities/a29.jpg';
import a30 from '../Photos/Activities/a30.jpg';
import a31 from '../Photos/Activities/a31.jpg';
import a32 from '../Photos/Activities/a32.jpg';
import a33 from '../Photos/Activities/a33.jpg';
import a33w from '../Photos/Activities/a33w.jpg';
import a34 from '../Photos/Activities/a34.jpg';
import a34w from '../Photos/Activities/a34w.jpg';
import a35 from '../Photos/Activities/a35.jpg';
import a36 from '../Photos/Activities/a36.jpg';
import a37 from '../Photos/Activities/a37.jpg';
import a38 from '../Photos/Activities/a38.jpg';
import a39 from '../Photos/Activities/a39.jpg';
import a40 from '../Photos/Activities/a40.jpg';
import a41 from '../Photos/Activities/a41.jpg';
import a42 from '../Photos/Activities/a42.jpg';
import a43 from '../Photos/Activities/a43.jpg';
import a44 from '../Photos/Activities/a44.jpg';
import a45 from '../Photos/Activities/a45.jpg';
import a46 from '../Photos/Activities/a46.jpg';
import a47 from '../Photos/Activities/a47.jpg';
import a48 from '../Photos/Activities/a48.jpg';
import a49 from '../Photos/Activities/a49.jpg';
import a50 from '../Photos/Activities/a50.jpg';
import a51 from '../Photos/Activities/a51.jpg';
import a52 from '../Photos/Activities/a52.jpg';
import a53 from '../Photos/Activities/a53.jpg';
import a54 from '../Photos/Activities/a54.jpg';
import a55 from '../Photos/Activities/a55.jpg';
import a56 from '../Photos/Activities/a56.jpg';
import a57 from '../Photos/Activities/a57.jpg';
import a58 from '../Photos/Activities/a58.jpg';
import a59 from '../Photos/Activities/a59.jpg';
import a60 from '../Photos/Activities/a60.jpg';
import a61 from '../Photos/Activities/a61.jpg';
import a62 from '../Photos/Activities/a62.jpg';
import a63 from '../Photos/Activities/a63.jpg';
import a64 from '../Photos/Activities/a64.jpg';
import a65 from '../Photos/Activities/a65.jpg';
import a66 from '../Photos/Activities/a66.jpg';
import a67 from '../Photos/Activities/a67.jpg';
import a68 from '../Photos/Activities/a68.jpg';
import a69 from '../Photos/Activities/a69.jpg';
import a74 from '../Photos/Activities/a74.jpg';
import a75 from '../Photos/Activities/a75.jpg';
import a76 from '../Photos/Activities/a76.jpg';
import a77 from '../Photos/Activities/a77.jpg';
import a78 from '../Photos/Activities/a78.jpg';
import a80 from '../Photos/Activities/a80.jpg';
import a81 from '../Photos/Activities/a81.jpg';
import a82 from '../Photos/Activities/a82.jpg';
import a83 from '../Photos/Activities/a83.jpg';
import a84 from '../Photos/Activities/a84.jpg';
import a85 from '../Photos/Activities/a85.jpg';
import a86 from '../Photos/Activities/a86.jpg';
import a87 from '../Photos/Activities/a87.jpg';
import a88 from '../Photos/Activities/a88.jpg';
import a89 from '../Photos/Activities/a89.jpg';
import a90 from '../Photos/Activities/a90.jpg';
import a91 from '../Photos/Activities/a91.jpg';
import a92 from '../Photos/Activities/a92.jpg';
import a93 from '../Photos/Activities/a93.jpg';
import a94 from '../Photos/Activities/a94.jpg';

//#endregion

function GaleriaModal({handleGallery,activities,language}) {

const arr1 = [p1,p3,p4,p7,p8,p30,p31,p32]
const arr2 = [p9,p10,p11,p12,p13,p14,p15,p16]
const arr3 = [p17,p18,p19,p20,p21,p22,p23,p24]
const arr4 = [p25,p26,p27,p28,p29,p30,p31,p32]

const arr1a = [p1,p3,p4,p7,p8w,p30,p31,p32]
const arr2a = [p9w,p10,p11w,p12,p13,p14,p15,p16]
const arr3a = [p17,p18,p19,p20,p21,p22,p23,p24]
const arr4a = [p25,p26,p27,p28,p29,p30,p31,p32]

const arrA0 = [a68,a69,a74,a75,a2,a3,a4,a1]
const arrA01 = [a76,a77,a78,a80,a81,a82,a83]
const arrA02 = [a84,a85,a86,a87,a88,a89,a90,a91]
const arrA03 = [a92,a93,a94,a63,a64,a65,a66,a67]

const arrA1 = [a2,a10,a4,a14,a20,a54,a8,a38]
const arrA2 = [a9,a3,a11,a13,a5,a16,a7,a55]
const arrA3 = [a17,a18,a19,a6,a21,a22,a23,a24]
const arrA4 = [a25,a26,a27,a28,a29,a30,a31,a32]
const arrA5 = [a33,a34,a35,a36,a37,a38,a39,a40]
const arrA6 = [a41,a42,a43,a44,a45,a46,a47,a48]
const arrA7 = [a49,a50,a51,a52,a53,a54,a55,a56]
const arrA8 = [a57,a58,a59,a60,a61,a62,a63,a64]
const arrA9 = [a65,a66,a67,]

const arrA0a = [a68,a69,a74,a75,a2,a3,a4,a1]
const arrA01a = [a76,a77,a78,a80,a81,a82,a83]
const arrA02a = [a84,a85,a86,a87,a88,a89,a90,a91]
const arrA03a = [a92,a93,a94,a63,a64,a65,a66,a67]

const arrA1a = [a2,a10,a4,a14,a20,a54,a8,a38]
const arrA2a = [a9,a3,a11,a13,a5,a16,a7,a55]
const arrA3a = [a17,a18,a19,a6,a21,a22,a23,a24]
const arrA4a = [a25,a26,a27,a28,a29,a30,a31,a32]
const arrA5a = [a33w,a34w,a35,a36,a37,a38,a39,a40]
const arrA6a = [a41,a42,a43,a44,a45,a46,a47,a48]
const arrA7a = [a49,a50,a51,a52,a53,a54,a55,a56]
const arrA8a = [a57,a58,a59,a60,a61,a62,a63,a64]
const arrA9a = [a65,a66,a67,a1,a2,a3,a4]

const photosJollyPhonics = [arr1, arr2, arr3, arr4]
const photosActivities = [arrA0, arrA1, arrA2, arrA3, arrA4, arrA5, arrA6, arrA7, arrA8, arrA9]

const photosJollyPhonicsOnePhoto = arr1a.concat(arr2a,arr3a,arr4a)
const photosActivitiesOnePhoto = arrA0a.concat(arrA01a,arrA02a,arrA03a,arrA1a,arrA2a,arrA3a,arrA4a,arrA5a,arrA6a,arrA7a,arrA8a,arrA9a)

const[currentArray, setCurrentArray] = useState(0);
const[currentImage, setCurrentImage] = useState(0);

const [distance, setDistance] = useState(0);
const [speed, setSpeed] = useState(3);


const handleNext = (activities) => {
  var num = activities ? 8 : 3;

  if (currentArray == num)
  setCurrentArray(currentArray - num)
  else
  setCurrentArray(currentArray + 1)

  var num2 = activities ? 70 : 31;

  if (currentImage == num2)
  setCurrentImage(currentImage - num2)
  else
  setCurrentImage(currentImage + 1)

  setDistance(d => d + 100)
  setSpeed(0.5)
}


const handlePrevious = (activities) => {
  var num = activities ? 8 : 3;
  if (currentArray == 0)
  setCurrentArray(num)
  else
  setCurrentArray(currentArray - 1)

  var num2 = activities ? 70 : 31;

  if (currentImage == 0)
  setCurrentImage(num2)
  else
  setCurrentImage(currentImage - 1)

  setDistance(d => d - 100)
  setSpeed(0.5)
}

const rotateAngles = ["rotate-6", "-rotate-6", "rotate-3","-rotate-3"]

  const styles={
    modal:{
      position: 'fixed', 
      zIndex: 90,  
      left: 0,
      top: 0,
      width: '100%', 
      height: '100%', 
      overflow: 'hidden', 
      backgroundColor: '#e9f9f3',
    },
    zavriet:{
      width: '50px'
    },

  }
  
  const [imageOpen, setImageOpen] = useState(false)
  const [image, setImage] = useState();

  const handleImageClose = (n) => {
    setImageOpen(prevState => !prevState)
    imageOpen ? 
  document.body.style.overflowY = 'unset'
  :
  document.body.style.overflowY = 'hidden'
  }

 const handleImageClick = (i) => {
  setImage(i.target.src)
  setImageOpen(prevState => !prevState)
  imageOpen ? 
  document.body.style.overflowY = 'unset'
  :
  document.body.style.overflowY = 'hidden'
  }


  

  return (

<motion.div
initial={{ opacity: 0 }}
animate={{ opacity: 1 }}
exit={{ opacity: 0 }}
transition={{ delay: 0.1 }}
id="galeriaModal1" style={styles.modal } 
class="pt-[20px] md:pt-[50px] landscape:md:pt-2 landscape:lg:pt-[12px]
 lg:pt-[100px] landscape:xxl:pt-[20px] landscape:2xl:pt-[50px]">


<div id="galeriaModalDivTop" class="flex mx-20">
  <div class="flex-none w-46 h-14">
    <button onClick={handleGallery}> <p class="absolute s:relative text-green-600 z-50 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-md md:text-lg sm:text-lg text-lg" style={styles.headerText}>{language ? "Back to gallery" : "Spať na celú galériu"}</p></button>
  </div>
<div class="grow h-14 ...">



</div>
  <div id="zavriet" class="hidden s:block flex-none h-14 2xl:w-w-[48px] xl:w-[48px] lg:w-[32px] md:w-[48px] sm:w-[42px] w-[36px]">
   <button onClick={handleGallery}><img src={zavriet} alt="zavriet" style={styles.zavriet}/></button>
  </div>
</div>

<div id="galeriaModalDiv" class="hidden sm:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 lg:gap-2 xl:gap-8 p-4 px-24 pt-12 landscape:md:pt-0 landscape:lg:pt-0">
  {(activities? photosActivities : photosJollyPhonics)[currentArray].map(i => 
    <div class="group cursor-pointer relative">
    <motion.img
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.05 }}
      src={i}
      alt="Img"
      onClick={handleImageClick}
      class="
      transition-all duration-1000 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]

      w-full h-64 object-cover rounded-lg
     transition-transform transform
      scale-100 group-hover:scale-105 hover:rotate-360"/>
  </div>)}
</div>

<div class="grid sm:hidden grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 p-4 px-24 pt-12 landscape:pt-0 landscape:s:pt-12
h-full absolute top-0 start-0 z-0 flex items-center justify-center justify-items-center w-full
">
  {
    <div class="group cursor-pointer relative">
    <motion.img
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.05 }}
      src={(activities? photosActivitiesOnePhoto : photosJollyPhonicsOnePhoto)[currentImage]}
      alt="Img"
      onClick={handleImageClick}
      class="w-full h-64 object-cover rounded-lg transition-transform transform scale-100 transition-all duration-1000 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]
      "/>
  </div>}
</div>

  <div class="
 s:top-[-150px] sm:top-20 md:top-20 lg:top-20 xl:top-0
  h-full absolute top-0 start-0 z-30 flex items-center justify-center" >
    <button onClick={() =>handlePrevious(activities)} type="button" class="relative top-0 start-0 z-30 flex items-center justify-center px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
          <span class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-black/10 dark:bg-gray-800/30 group-hover:bg-black/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg class="w-4 h-4 text-green-600 dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
              </svg>
              <span class="sr-only">Previous</span>
          </span>
      </button>
  </div>
  
  <div class="
  s:top-[-150px] sm:top-20 md:top-20 lg:top-20 xl:top-0
  h-full absolute top-0 end-0 z-30 flex items-center justify-center" >
    <button onClick={() => handleNext(activities)} type="button" class="relative top-0 end-0 z-30 flex items-center justify-center px-4 cursor-pointer group focus:outline-none" data-carousel-next>
        <span class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-black/10 dark:bg-gray-800/30 group-hover:bg-black/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-green-600 dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <span class="sr-only">Next</span>
        </span>
    </button>
  </div>
    
  { imageOpen && <ImageModal handleImageClose={handleImageClose} activities={activities} image={image} activ/>}

  <motion.div
   id='imgGalleryMotion'
   initial={{ x:-400 }}
   animate={{ x:distance}}
   transition={{ duration:speed }}
   class="hidden lg:block 2xl:w-[520px] xl:w-[420px] lg:w-[360px] md:w-[220px] sm:w-[150px] w-[100px]"
  >
    <img id="imgDazdovka" class="s
    left-[leftValue]
    
    w-[500px] left-[50px]  [transform:rotateY(180deg)] relative left-[50px] z-0 2xl:top-[30px] xxl:top-[20px] lg:top-[10px] sm:top-[50px] justify-self-start inline-block" style={styles.dazdovka} src={dazdovka} alt="" />
  </motion.div>
  
  <motion.div
   id='slnko'
   initial={{ x:40 }}
   animate={{ x:0}}
   transition={{ duration:speed }}
   class="hidden lg:block 2xl:w-[520px] xl:w-[420px] lg:w-[360px] md:w-[220px] sm:w-[150px] w-[100px]"
  >
    <img id="slnko" class="s
    left-[leftValue]
     left-[50px] w-[40%]  [transform:rotateY(180deg)] relative left-[50px] z-0 2xl:top-[-200px] 2xl:left-[1450px] xxl:top-[200px] lg:top-[10px] sm:top-[50px] justify-self-start inline-block" style={styles.slnko} src={slnko} alt="" />
  </motion.div>
</motion.div>

  )
}

export default GaleriaModal