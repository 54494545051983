import React from 'react'
import zajac from "../Images/zajac.svg"
import kvetik from "../Images/kvetik.svg"


function CoPonukam({language}) {
    const styles={}

    const text1 = "Ponúkam dva vzdelávacie kurzy."
    const text21 = " každý týždeň prídeme odučiť angličtinu do vašej škôlky podľa vaších tém týždňa."
    const text2 = "Viem vám zabezpečiť angličtinu podľa vaších výchovno vzdelávacích aktivít. To znamená, že"  
    const text22 =" Je to obľúbená voľba mnohých škôlok. Vytvárame tak krásnu spoluprácu, ktorá dáva deťom zmysel. Veľmi nám záleží na inklúzii a veríme, že každé dieťa by malo mať šancu na kvalitné vzdelanie a pozitívny štart do života. Veríme, že vzdelávanie má byť pre deti zábavné a vzrušujúce. Veríme, že každé dieťa napreduje individuálne podľa svojho tempa a schopností."
    const text3 = "Druhý vzdelávací kurz, ktorý ponúkam je Jolly Phonics."
    const text31 = " Je to set krásne ilustrovaných knižiek. Každá knižka má niekoľko písmenok, ktoré sa deti postupne učia. Ku každému písmenku je príbeh a pesnička, alebo básnička. Deti sa tak učia správnu výslovnosť každého písmenka a ako sa správne číta. Každá lekcia má časť, kde si prejdeme obrázok, naučíme sa správnu výslovnosť a potom kreatívnu časť, kde si deti písmenko vyrábajú, alebo kreslia. Deti si ľahšie zapamätajú dané písmeno, nakoľko ho mali šancu preskúmať zážitkom a vlastnou tvorbou. Pracujeme so zmyslami a dávame deťom šancu byť kreatívne a neustále aktívne."

    const text1EN = "I offer two educational courses. "
    const text21EN = " every week we will come to your nursery to teach English according to your topics of the week."
    const text2EN = "I can provide you with English according to your educational activities. This means that "  
    const text22EN =' It is a popular choice of many nurseries. We create a beautiful collaboration that gives children meaning. We communicate with the children in English as much as possible during the entire lesson. I believe that every child should have a chance for a good quality education and a positive start in life. Education is fun and exciting for children. I do not accept "stereotyping" and believe that each child progresses individually according to his/her own pace and abilities.'
    const text3EN = "The second educational course I offer is Jolly Phonics. "
    const text31EN = " It is a set of beautifully illustrated books. Each book has several letters that children gradually learn. Each letter has a story and a song or a poem. Children learn the correct pronunciation of each letter and how to read it correctly. Each lesson has a part where we go through the picture, learn the correct pronunciation and then a creative part where the children make or draw the letter. Children can remember the given letter more easily because they had the chance to explore it through experience and their own creation. We work with the senses and give children the chance to be creative and active."

  return (
    <div class='bg-[#f5f1fe] h-screen w-screen pt-24' id="coponukam">
      <div id="coPonukamMid" class='absolute top-[1550px] '></div>
        <div class="z-0 flex-row h-8 s:h-24 md:h-24 lg:h-28 xl:h-48 ">
          <img class="z-0 w-8 s:w-16  lg:w-24 xl:w-36 2xl:w-48 relative top-[24px] left-[60px] s:top-[48px] s:left-[60px] justify-self-start inline-block" style={styles.zajac} src={zajac} alt="" />
        </div>
        <div class=" flex-row">
            <div class="flex items-center justify-center">
               <h3 class="text-orange-600 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-lg z-30" style={styles.headerText}>{language ? "WHAT DO I OFFER" : "ČO PONÚKAM"}</h3>
            </div> 
          </div>
        <div class="flex flex-row
         w-full 
         sm:w-full 
         md:w-full md:gap-0 md:m-0 md:px-16 
         lg:w-full lg:gap-0 lg:m-0 lg:px-24 
         xl:w-240 xl:gap-8 xl:m-6 xl:px-48 
         2xl:w-160 2xl:gap-24 2xl:m-8 2xl:px-72
         ">
            <div class="p-6 text-black z-10 hidden sm:hidden md:block">
              <p class="font-[satoshi-Bold] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-xs  pb-6" style={styles.text1}>{language ? text1EN : text1}</p>
              <span class="font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-xs" style={styles.text}>{language ? text2EN : text2}</span>
              <span class="font-[satoshi-Bold] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-xs" style={styles.text1}>{language ? text21EN : text21}</span>
              <span class="font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-xs" style={styles.text}>{language ? text22EN : text22}</span>
            </div>

            <div class="p-6 text-black hidden sm:hidden md:block">
              <span class="font-[satoshi-Bold] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-xs" style={styles.text1}>{language ? text3EN : text3}</span>
              <span class="font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-xs" style={styles.text}>{language ? text31EN : text31}</span> 
            </div>

            <div class="p-6 text-black z-10 block md:hidden max-w-[94%]">
              <p class="font-[satoshi-Bold] 2xl:text-2xl xxl:text-base xl:text-xl  lg:text-base md:text-md sm:text-sm s:text-sm text-[11px] leading-[0.75rem] s:pb-6 pb-0" style={styles.text1}>{language ? text1EN : text1}</p>
              <p class="font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-[11px] leading-[0.75rem]" style={styles.text}>{language ? text2EN : text2}</p>
              <p class="font-[satoshi-Bold] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-[11px] leading-[0.75rem]" style={styles.text1}>{language ? text21EN : text21}</p>
              <p class="font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-[11px] leading-[0.75rem]" style={styles.text}>{language ? text22EN : text22}</p>
              <p class="pt-5 md:pt-0 font-[satoshi-Bold] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-[11px] leading-[0.75rem]" style={styles.text1}>{language ? text3EN : text3}</p>
              <p class="font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-md sm:text-sm s:text-sm text-[11px] leading-[0.75rem]" style={styles.text}>{language ? text31EN : text31}</p> 
            </div>

        </div> 
        <img class="
        2xl:w-[170px] xl:w-[170px] lg:w-[110px] md:w-[70px] sm:w-[60px] w-[25px] 
        2xl:left-[1600px] xl:left-[1600px] landscape:xl:left-[1640px] lg:left-[910px] landscape:lg:left-[910px] md:left-[690px] landscape:md:left-[725px] sm:left-[500px] s:left-[540px]
        left-[330px] landscape:left-[435px]
        2xl:bottom-[620px] xl:bottom-[620px] landscape:xl:bottom-[660px] landscape:lg:bottom-[480px] lg:bottom-[620px] md:bottom-[580px] sm:bottom-[620px] s:bottom-[580px] landscape:s:bottom-[580px]
        bottom-[440px] landscape:bottom-[320px]
         relative  
         " style={styles.kvetik} src={kvetik} alt="" />

</div>

  )
}

export default CoPonukam