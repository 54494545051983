import React from 'react'
import hviedzicka2 from "../Images/hviezdicka_2.svg"
import oblak from "../Images/oblak.svg"
import vykricnik from "../Images/vykricnik.svg"
import kruh from "../Images/kruh.svg"
import sestuholnik from "../Images/sestuholnik.svg"
import zelenaHviezda from "../Images/hviezda_zelena.svg"
import logoTop from "../Images/logo_top.svg"

function Main({language}) {
    const styles={} 

    const text1="Learn & Shine Academy:" 
    const text2="Kurzy angličtiny pre deti vo veku 2-7 rokov."
    const text3=""/*"nebudú nudiť. Kurzy pre"*/
    const text4=""//" happy"
    const text5=""//"and creative little souls."

    const text21="Learn & Shine Academy are English courses"
    const text22="for happy and creative little souls."//"for kids from 2-7 years."
    const text23=""//"Courses for"
    const text24=""//" happy and creative"
    const text25=""//" little souls."courses for happy and creative little souls.


  return (
    <div id="main" class='bg-[#fff7f1] h-screen w-screen'>
      <div id="mainMid" class='absolute top-[-100px]'></div>
      <div class="flex flex-row h-1/3 xl:h-2/4 landscape">
        <div class="w-1/3"><img class="w-6/6 z-0 top-10 left-4 s:top-8 s:left-8 sm:top-16 sm:left-24 md:left-8 md:top-8 landscape:top-4 landscape:left-8 relative" style={styles.hviezdicka2} src={hviedzicka2} alt="" /></div>
        <div class="w-full landscape:w-1/3 md:w-1/3"><img style={styles.logoTop} src={logoTop} class="p-4 w-[1200px]" alt="" /></div>
        <div class="w-1/3"><img class="landscape:lg:left-8 landscape:w-5/6 landscape:sm:w-6/6 sm:w-4/6 top-8 left-[-24px] sm:top-16 sm:left-14 lg:left-28 landscape:top-8 landscape:left-0  relative" style={styles.kruh} src={kruh} alt="" /></div>
      </div>
      <div class="flex flex-row h-1/3 landscape:pt-8 ">
        <div class="w-1/5">
          <img id="imgSestuholnik" class="w-6/6 z-0 bottom-12 left-4 lg:w-5/6 lg:bottom-12 lg:left-4 relative" style={styles.sestuholnik} src={sestuholnik} alt="" /></div>
      <div class="w-full flex items-start justify-center z-10 ">
        <div class="flex-row text-align text-center">
          <p class="z-10 font-[satoshi-medium] 2xl:text-[2.25em] xl:text-[2.25em] lg:text-[2.25em] md:text-[1.75em] sm:text-[1.5em] text-[0.75em]" >{language ? text21 : text1}</p>
          <p class="z-10 font-[satoshi-medium] 2xl:text-[2.25em] xl:text-[2.25em] lg:text-[2.25em] md:text-[1.75em] sm:text-[1.5em] text-[0.75em]" >{language ? text22 : text2}</p>
          <p class="z-10 font-[satoshi-medium] 2xl:text-[2.25em] xl:text-[2.25em] lg:text-[2.25em] md:text-[1.75em] sm:text-[1.5em] text-[0.75em]" >{language ? text23 : text3}
          <span class="z-10 font-[satoshi-black] text-[1em]" >{language ? text24 : text4}</span></p>
          <p class="z-10 font-[satoshi-black] 2xl:text-[2.25em] xl:text-[2.25em] lg:text-[2.25em] md:text-[1.75em] sm:text-[2.25em] text-[0.75em]" >{language ? text25 : text5}</p>
        </div>
      </div>
      <div class="w-1/5"><img id="imgVykricnik" class="w-6/6 z-0 bottom-10 left-[-8px] landscape:bottom-0 landscape:lg:bottom-0 landscape:lg:left-6 lg:w-4/6 z-0 lg:bottom-52 lg:left-0 relative" style={styles.vykricnik} src={vykricnik} alt="" /></div>
      </div>
      <div class="flex flex-row h-1/3 landscape:pt-16 landscape:md:pt-28 landscape:lg:pt-20 landscape:xxl:pt-8 landscape:2xl:pb-0">
        <div class="w-1/3">
            <img class="left-14 w-5/5 z-0 landscape:bottom-16 landscape:xl:bottom-36 bottom-24 s:left-2 xl:bottom-36
            xl:left-64 relative" style={styles.oblak} src={oblak} alt="" />
        </div>
        <div class="w-1/2  text-align text-center"><a href="#onas"
                            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full z-[90]">
                            {language ? "MORE ABOUT" : "VIAC O NÁS"}</a>
        </div>
        <div class="w-1/3">
          <img id="imgZelenaHviezda"  class="right-14 w-3/5 landscape:bottom-16 landscape:left-4 landscape:xl:bottom-48 landscape:xl:left-0 z-0
          bottom-24 md:bottom-32 md:left-0 xl:bottom-48 xl:left-8 2xl:bottom-56 relative" style={styles.zelenaHviezda} src={zelenaHviezda} alt="" />
        </div>
      </div>
     </div>
  )
}

export default Main